import { Routes, Route, useParams } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Product from './Pages/Product';
import Purchase from './Pages/Purchases';
import Upload from "./Pages/Upload";
import SuppliersData from "./Pages/SuppliersData";
import ViewRecord from "./Pages/ViewRecord";
import ViewRawsRecord from "./Pages/ViewRawsData";
import CompareQuotes from "./Pages/CompareQuotes";
import SupplierContacts from "./Pages/SupplierContacts";
import ForgotPassword from "./Pages/ForgotPassword";
import Raws from './Pages/Raws';
import AddRaws from "./Pages/AddRaws";

const App = () => {

  return (
      <>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/purchases" element={<Purchase />} />
          <Route path="/raws" element={<Raws />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
            <Route exact path="/product/:productId" element={<Product/>}/>
            <Route path="/suppliers-data" element={<SuppliersData />} />
            <Route path="/suppliers-data/:supplierId" element={<SuppliersData />} />
            <Route path="/suppliers-contacts" element={<SupplierContacts />} />
            <Route exact path="/view/:recordId/:dataId" element={<ViewRecord />} />
            <Route exact path="/compare-quotes/:keyword" element={<CompareQuotes />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/view-raws/:dataId" element={<ViewRawsRecord />} />
          <Route exact path="/add-raws" element={<AddRaws />} />
        </Routes>
      </>
  );
};

export default App;