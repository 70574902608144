import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import Header from "./Components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Modal, Tab, Tabs} from "react-bootstrap";

const ViewRawsRecord = () => {
    const [fileList, setFileList] = useState([]);
    const [rawsData, setRawsData] = useState(0);
    const [id, setId] = useState('');
    const [rawMaterialName, setRawMaterialName] = useState('');
    const [activeInactive, setActiveInactive] = useState('');
    const [identityTestingCost, setIdentityTestingCost] = useState('');
    const [testingMethod, setTestingMethod] = useState('');
    const [typeMicroLimit, setTypeMicroLimit] = useState('');
    const [commonName, setCommonName] = useState('');
    const [botanicalName, setBotanicalName] = useState('');
    const [hoc, setHOC] = useState('');
    const [part, setPart] = useState('');
    const [form, setForm] = useState('');
    const [ratio, setRatio] = useState('');
    const [lodNmt, setLOD_NMT] = useState('');
    const [loiNmt, setLOI_NMT] = useState('');
    const [ingredientDeclarationOnLabel, setIngredientDeclarationOnLabel] = useState('');
    const [identity, setIdentity] = useState('');
    const [limit0, setLimit0] = useState('');
    const [limit0Method, setLimit0Method] = useState('');
    const [limit0Reference, setLimit0Reference] = useState('');
    const [limit0TestingCost, setLimit0TestingCost] = useState('');
    const [active1, setActive1] = useState('');
    const [limit1, setLimit1] = useState('');
    const [limit1Method, setLimit1Method] = useState('');
    const [limit1Reference, setLimit1Reference] = useState('');
    const [limit1TestingCost, setLimit1TestingCost] = useState('');
    const [active2, setActive2] = useState('');
    const [limit2, setLimit2] = useState('');
    const [limit2Method, setLimit2Method] = useState('');
    const [limit2Reference, setLimit2Reference] = useState('');
    const [limit2TestingCost, setLimit2TestingCost] = useState('');
    const [appearance, setAppearance] = useState('');
    const [color, setColor] = useState('');
    const [odor, setOdor] = useState('');
    const [taste, setTaste] = useState('');
    const [bulk, setBulk] = useState('');
    const [tapped, setTapped] = useState('');
    const [meshSize, setMeshSize] = useState('');
    const [arsenic, setArsenic] = useState('');
    const [cadmium, setCadmium] = useState('');
    const [leadx, setLeadx] = useState('');
    const [mercury, setMercury] = useState('');
    const [tamc, setTAMC] = useState('');
    const [tymc, setTYMC] = useState('');
    const [coliforms, setColiforms] = useState('');
    const [eColi, setEColi] = useState('');
    const [salmonellaSpp, setSalmonellaSpp] = useState('');
    const [staphAureus, setStaphAureus] = useState('');
    const [absent, setAbsent] = useState('');
    const [milk, setMilk] = useState('');
    const [egg, setEgg] = useState('');
    const [soy, setSoy] = useState('');
    const [fish, setFish] = useState('');
    const [shellfish, setShellfish] = useState('');
    const [treeNuts, setTreeNuts] = useState('');
    const [peanuts, setPeanuts] = useState('');
    const [wheat, setWheat] = useState('');
    const [gluten, setGluten] = useState('');
    const [overage, setOverage] = useState('');
    const [cost, setCost] = useState('');
    const [costUnit, setCostUnit] = useState('');
    const [costG, setCostG] = useState('');
    const [moqKg, setMOQKg] = useState('');
    const [amazonTesting, setAmazonTesting] = useState('');
    const [laboratory, setLaboratory] = useState('');
    const [solubilityGML, setSolubilityGML] = useState('');
    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);

    let { dataId } = useParams();
    useEffect(() => {
        axios.get(`raws/get-raws-data/${dataId}`)
            .then(response => {
                const raws = response.data.data;
                setRawsData(raws);
                setId(raws['Id']);
                setRawMaterialName(raws['RawMaterialName']);
                setActiveInactive(raws['ActiveInactive']);
                setIdentityTestingCost(raws['IdentityTestingCost']);
                setTestingMethod(raws['TestingMethod']);
                setTypeMicroLimit(raws['Type_MicroLimit']);
                setCommonName(raws['CommonName']);
                setBotanicalName(raws['BotanicalName']);
                setHOC(raws['HOC']);
                setPart(raws['Part']);
                setForm(raws['Form']);
                setRatio(raws['Ratio']);
                setLOD_NMT(raws['LOD_NMT']);
                setLOI_NMT(raws['LOI_NMT']);
                setIngredientDeclarationOnLabel(raws['IngredientDeclarationonLabel']);
                setIdentity(raws['Identity']);
                setLimit0(raws['Limit0']);
                setLimit0Method(raws['Limit0Method']);
                setLimit0Reference(raws['Limit0Reference']);
                setLimit0TestingCost(raws['Limit0TestingCost']);
                setActive1(raws['Active1']);
                setLimit1(raws['Limit1']);
                setLimit1Method(raws['Limit1Method']);
                setLimit1Reference(raws['Limit1Reference']);
                setLimit1TestingCost(raws['Limit1TestingCost']);
                setActive2(raws['Active2']);
                setLimit2(raws['Limit2']);
                setLimit2Method(raws['Limit2Method']);
                setLimit2Reference(raws['Limit2Reference']);
                setLimit2TestingCost(raws['Limit2TestingCost']);
                setAppearance(raws['Appearance']);
                setColor(raws['Color']);
                setOdor(raws['Odor']);
                setTaste(raws['Taste']);
                setBulk(raws['Bulk']);
                setTapped(raws['Tapped']);
                setMeshSize(raws['MeshSize']);
                setArsenic(raws['Arsenic']);
                setCadmium(raws['Cadmium']);
                setLeadx(raws['Leadx']);
                setMercury(raws['Mercury']);
                setTAMC(raws['TAMC']);
                setTYMC(raws['TYMC']);
                setColiforms(raws['Coliforms']);
                setEColi(raws['E_coli']);
                setSalmonellaSpp(raws['Salmonellaspp']);
                setStaphAureus(raws['Staphaureus']);
                setAbsent(raws['Absent']);
                setMilk(raws['Milk']);
                setEgg(raws['Egg']);
                setSoy(raws['Soy']);
                setFish(raws['Fish']);
                setShellfish(raws['Shellfish']);
                setTreeNuts(raws['TreeNuts']);
                setPeanuts(raws['Peanuts']);
                setWheat(raws['Wheat']);
                setGluten(raws['Gluten']);
                setOverage(raws['Overage']);
                setCost(raws['Cost']);
                setCostUnit(raws['CostUnit']);
                setCostG(raws['Cost_g']);
                setMOQKg(raws['MOQ_kg']);
                setAmazonTesting(raws['AmazonTesting']);
                setLaboratory(raws['Laboratory']);
                setSolubilityGML(raws['Solubility_g_mL']);
            });


        axios.get(`raws/get-raws-file/${dataId}`)
            .then(response => {
                const raws = response.data.data;
                setFileList(raws);
                
            });

    }, [dataId]);

    const onSaveClick = () => {
        let dataObj = {
            id,
            rawsId : dataId,
            rawMaterialName,
            activeInactive,
            identityTestingCost,
            testingMethod,
            typeMicroLimit,
            commonName,
            botanicalName,
            hoc,
            part,
            form,
            ratio,
            lodNmt,
            loiNmt,
            ingredientDeclarationOnLabel,
            identity,
            limit0,
            limit0Method,
            limit0Reference,
            limit0TestingCost,
            active1,
            limit1,
            limit1Method,
            limit1Reference,
            limit1TestingCost,
            active2,
            limit2,
            limit2Method,
            limit2Reference,
            limit2TestingCost,
            appearance,
            color,
            odor,
            taste,
            bulk,
            tapped,
            meshSize,
            arsenic,
            cadmium,
            leadx,
            mercury,
            tamc,
            tymc,
            coliforms,
            eColi,
            salmonellaSpp,
            staphAureus,
            absent,
            milk,
            egg,
            soy,
            fish,
            shellfish,
            treeNuts,
            peanuts,
            wheat,
            gluten,
            overage,
            cost,
            costUnit,
            costG,
            moqKg,
            amazonTesting,
            laboratory,
            solubilityGML
        };
        axios.put(`raws/update-raws-data`, dataObj)
            .then(() => toast.success('Data updated Successfully!'));
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDownload = (fileId) => {
        var baseUrl = axios.defaults.baseURL;
        baseUrl += `raws/download/${fileId}`
        window.open(baseUrl);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('rawsId', dataId);
        try {
            const response = await axios.post('raws/add-raws-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Response:', response.data);
            toast.success('File added Successfully!')
            handleClose()
            window.location.reload()
        } catch (error) {
            console.error('Error submitting data:', error);
            toast.success('Error submitting file!')
            handleClose()
        }
    }

    return (
        <>
            <Header />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>File Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Upload File</label>
                    <input type="file" onChange={handleFileChange}/>
                    <Button variant="secondary" onClick={handleSubmit}>
                        Save
                    </Button>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <section className="section pb-0" style={{ marginBottom: '130px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-12" style={{ marginTop: '20px' }}>
                            <h2 className="h5">
                                <a href="/raws"><i className="ti-angle-left"></i> Back</a>
                            </h2>
                            <div>
                                <div id={'tabs-page'}>
                                    <Tabs defaultActiveKey="data" id="uncontrolled-tab-example">
                                        <Tab eventKey="data" title="Data">
                                            <article className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12 mb-12">
                                                            <div className="widget widget-categories">
                                                                <div className="row">
                                                            <span className="col-lg-12 mb-12">
                                                                <ul className="list-unstyled widget-list">
                                                                    <li><span className="d-flex"><span
                                                                        className="suphead">Id</span><span
                                                                        className="supseperator">:</span><span
                                                                        className="supbody"><input type="number"
                                                                                                   step="0.01"
                                                                                                   className="form-control"
                                                                                                   value={id}
                                                                                                   onChange={(e) => setId(e.target.value)}/></span></span></li>
                                                                    <li><span className="d-flex"><span
                                                                        className="suphead">Raw Material Name</span><span
                                                                        className="supseperator">:</span><span
                                                                        className="supbody"><input type="text"
                                                                                                   className="form-control"
                                                                                                   value={rawMaterialName}
                                                                                                   onChange={(e) => setRawMaterialName(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Active/Inactive</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={activeInactive}
                                                                                               onChange={(e) => setActiveInactive(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Identity Testing Cost</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="number" step="0.01"
                                                                                               className="form-control"
                                                                                               value={identityTestingCost}
                                                                                               onChange={(e) => setIdentityTestingCost(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Testing Method</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={testingMethod}
                                                                                               onChange={(e) => setTestingMethod(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Type (Micro Limit)</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={typeMicroLimit}
                                                                                               onChange={(e) => setTypeMicroLimit(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Common Name</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={commonName}
                                                                                               onChange={(e) => setCommonName(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Botanical Name</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={botanicalName}
                                                                                               onChange={(e) => setBotanicalName(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">HOC</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={hoc}
                                                                                               onChange={(e) => setHOC(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Part</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={part}
                                                                                               onChange={(e) => setPart(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Form</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={form}
                                                                                               onChange={(e) => setForm(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Ratio</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={ratio}
                                                                                               onChange={(e) => setRatio(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">LOD(NMT)</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={lodNmt}
                                                                                               onChange={(e) => setLOD_NMT(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">LOI(NMT)</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={loiNmt}
                                                                                               onChange={(e) => setLOI_NMT(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Ingredient Declaration On Label</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={ingredientDeclarationOnLabel}
                                                                                               onChange={(e) => setIngredientDeclarationOnLabel(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Identity</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={identity}
                                                                                               onChange={(e) => setIdentity(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Limit</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit0}
                                                                                               onChange={(e) => setLimit0(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Method</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit0Method}
                                                                                               onChange={(e) => setLimit0Method(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Reference</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit0Reference}
                                                                                               onChange={(e) => setLimit0Reference(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Testing Cost</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="number" step="0.01"
                                                                                               className="form-control"
                                                                                               value={limit0TestingCost}
                                                                                               onChange={(e) => setLimit0TestingCost(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Active1</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={active1}
                                                                                               onChange={(e) => setActive1(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Limit1</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit1}
                                                                                               onChange={(e) => setLimit1(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Method</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit1Method}
                                                                                               onChange={(e) => setLimit1Method(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Reference</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit1Reference}
                                                                                               onChange={(e) => setLimit1Reference(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Testing Cost</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="number" step="0.01"
                                                                                               className="form-control"
                                                                                               value={limit1TestingCost}
                                                                                               onChange={(e) => setLimit1TestingCost(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Active2</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={active2}
                                                                                               onChange={(e) => setActive2(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Limit2</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit2}
                                                                                               onChange={(e) => setLimit2(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Method</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit2Method}
                                                                                               onChange={(e) => setLimit2Method(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Reference</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit2Reference}
                                                                                               onChange={(e) => setLimit2Reference(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Testing Cost</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={limit2TestingCost}
                                                                                               onChange={(e) => setLimit2TestingCost(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Appearance</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={appearance}
                                                                                               onChange={(e) => setAppearance(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Color</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={color}
                                                                                               onChange={(e) => setColor(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Odor</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={odor}
                                                                                               onChange={(e) => setOdor(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Taste</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={taste}
                                                                                               onChange={(e) => setTaste(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Bulk</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={bulk}
                                                                                               onChange={(e) => setBulk(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Tapped</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={tapped}
                                                                                               onChange={(e) => setTapped(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Mesh Size</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={meshSize}
                                                                                               onChange={(e) => setMeshSize(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Arsenic</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={arsenic}
                                                                                               onChange={(e) => setArsenic(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Cadmium</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={cadmium}
                                                                                               onChange={(e) => setCadmium(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Lead</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={leadx}
                                                                                               onChange={(e) => setLeadx(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Mercury</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={mercury}
                                                                                               onChange={(e) => setMercury(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">TAMC</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={tamc}
                                                                                               onChange={(e) => setTAMC(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">TYMC</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={tymc}
                                                                                               onChange={(e) => setTYMC(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Coliforms</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={coliforms}
                                                                                               onChange={(e) => setColiforms(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">E. coli</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={eColi}
                                                                                               onChange={(e) => setEColi(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Salmonella spp.</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={salmonellaSpp}
                                                                                               onChange={(e) => setSalmonellaSpp(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Staph aureus</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={staphAureus}
                                                                                               onChange={(e) => setStaphAureus(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Absent</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={absent}
                                                                                               onChange={(e) => setAbsent(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Milk</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={milk}
                                                                                               onChange={(e) => setMilk(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Egg</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={egg}
                                                                                               onChange={(e) => setEgg(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Soy</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={soy}
                                                                                               onChange={(e) => setSoy(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Fish</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={fish}
                                                                                               onChange={(e) => setFish(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Shellfish</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={shellfish}
                                                                                               onChange={(e) => setShellfish(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Tree Nuts</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={treeNuts}
                                                                                               onChange={(e) => setTreeNuts(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Peanuts</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={peanuts}
                                                                                               onChange={(e) => setPeanuts(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Wheat</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={wheat}
                                                                                               onChange={(e) => setWheat(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Gluten*</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={gluten}
                                                                                               onChange={(e) => setGluten(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Overage</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={overage}
                                                                                               onChange={(e) => setOverage(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Cost</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="number" step="0.01"
                                                                                               className="form-control"
                                                                                               value={cost}
                                                                                               onChange={(e) => setCost(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Cost Unit</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={costUnit}
                                                                                               onChange={(e) => setCostUnit(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Cost/g</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="number" step="0.01"
                                                                                               className="form-control"
                                                                                               value={costG}
                                                                                               onChange={(e) => setCostG(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">MOQ (kg)</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={moqKg}
                                                                                               onChange={(e) => setMOQKg(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Amazon Testing</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={amazonTesting}
                                                                                               onChange={(e) => setAmazonTesting(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span
                                                                    className="suphead">Laboratory</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="text"
                                                                                               className="form-control"
                                                                                               value={laboratory}
                                                                                               onChange={(e) => setLaboratory(e.target.value)}/></span></span></li>
                                                                <li><span className="d-flex"><span className="suphead">Solubility (g/mL)</span><span
                                                                    className="supseperator">:</span><span
                                                                    className="supbody"><input type="number" step="0.01"
                                                                                               className="form-control"
                                                                                               value={solubilityGML}
                                                                                               onChange={(e) => setSolubilityGML(e.target.value)}/></span></span></li>

                                                                    <li>
                                                                        <button type="button" onClick={onSaveClick}
                                                                                className="btn btn-success">Save</button>
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </Tab>
                                        <Tab eventKey="files" title="Files">
                                            <article className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12 mb-12">
                                                            <div className="input-group">
                                                                <button className='btn btn-success'
                                                                        onClick={(e) => handleShow(e)}
                                                                        style={{borderRadius: 'unset'}}>
                                                                    <i className={'ti-upload'}></i> Upload Files
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-12" style={{marginTop:'5px'}}>
                                                            <table className="table table-bordered">
                                                                <thead className="tableHead">
                                                                <tr>
                                                                    <th scope="col">File name</th>
                                                                    <th scope="col" width={'10px'}>Download</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {fileList.map((p, index) => (

                                                                    <React.Fragment key={index}>
                                                                        <tr data-toggle={`collapse`}
                                                                            data-target={`#row${index}`}
                                                                            className="clickable"
                                                                            style={{cursor: 'pointer'}}>

                                                                            <td>{p.actualname}</td>
                                                                            <td onClick={(e) => handleDownload(p.filename)}><i className="ti-download"></i></td>


                                                                        </tr>

                                                                    </React.Fragment>))}


                                                                {
                                                                    fileList.length === 0 ? (<tr>
                                                                        <td colSpan="10"
                                                                            style={{textAlign: 'center'}}>No
                                                                            Files
                                                                        </td>
                                                                    </tr>) : (<tr></tr>)
                                                                }


                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>
                                                </div>
                                            </article>
                                        </Tab>
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="border-bottom border-default"></div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </section>
        </>
    );
}

export default ViewRawsRecord;
